import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useToast, useToggle } from 'shared/hooks'

import {
  Button,
  Input,
  ContainerScreen,
  Table,
  TableRef,
  Tags,
} from 'components'

import { ReactComponent as FiltersIcon } from 'assets/svg/serviceOrdersFilter.svg'
import { ReactComponent as PlusIcon } from 'assets/svg/plusSign.svg'
import { ReactComponent as InspectIcon } from 'assets/svg/listInspectIcon.svg'
import { ReactComponent as DeleteIcon } from 'assets/svg/trashCan.svg'

import styles from './AttendanceProfile.module.scss'
import { CellProps, Column } from 'react-table'
import { Pagination, Result } from 'services/types'

import {
  ATTENDANCE_ROLE_EN_PT,
  CoverageDriver,
  CoverageQueryResponse,
} from 'services/coverages'
import { AttendanceProfileDeleteModal } from 'domains/attendanceProfile/components/AttendanceProfileDeleteModal/AttendanceProfileDeleteModal'
import { useNavigate } from 'react-router-dom'
import { Filters } from 'domains/attendanceProfile/screens/AttendanceProfile/components/Filters/Filters'
import { FormProvider, useForm } from 'react-hook-form'
import {
  AttendanceProfileFilters,
  attendanceProfileFiltersSchema,
} from 'domains/attendanceProfile/schemas'
import { usePersistentFilters } from 'shared/hooks/usePersistentFilters/usePersistentFilters'
import { joiResolver } from '@hookform/resolvers/joi'
import { maskedDateTime } from 'utilities/date'
import { buildPath, paths } from 'routes'

const columns: Column<CoverageQueryResponse>[] = [
  {
    id: 'name',
    Header: 'Nome',
    accessor: (attendanceProfile) => attendanceProfile.name,
  },
  {
    id: 'role',
    Header: 'Função',
    accessor: (attendanceProfile) =>
      ATTENDANCE_ROLE_EN_PT[attendanceProfile.coverageType],
  },
  {
    id: 'serviceType',
    Header: 'Tipo de serviço',
    accessor: (attendanceProfile) => attendanceProfile,
    Cell: (cell: CellProps<CoverageQueryResponse>) => {
      const attendanceProfile = cell.value as CoverageQueryResponse
      return (
        <Tags
          className={styles.firstTag}
          keyId={attendanceProfile.id}
          keyLabel="attendance-profile"
          data={attendanceProfile.serviceTypes || []}
        />
      )
    },
  },
  {
    id: 'createdAt',
    Header: 'Cadastrado em',
    accessor: (attendanceProfile) =>
      maskedDateTime(attendanceProfile.createdAt),
  },
]

const AttendanceProfile: React.FC = () => {
  const [selectedAttendanceProfile, setSelectedAttendanceProfile] = useState<
    CoverageQueryResponse | undefined
  >(undefined)
  const [hasFilter, setHasFilter] = useState(false)
  const [resetPagination, setResetPagination] = useState(false)

  const navigate = useNavigate()
  const { addToast } = useToast()

  const filterSheetProps = useToggle()
  const removeAttendanceProfileModalProps = useToggle()

  const tableRef = useRef<TableRef>(null)

  const { getFilters, setFilters } =
    usePersistentFilters<AttendanceProfileFilters>('attendanceProfile')

  const form = useForm<AttendanceProfileFilters>({
    resolver: joiResolver(attendanceProfileFiltersSchema),
    defaultValues: getFilters(),
  })

  const { watch, setValue, register, unregister, reset } = form

  const name = watch('name')
  const coverageType = watch('coverageType')
  const state = watch('state')
  const city = watch('city')
  const district = watch('district')
  const account = watch('account')
  const tag = watch('tag')
  const serviceOrderType = watch('serviceOrderType')
  const occurrenceCategory = watch('occurrenceCategory')
  const serviceTypes = watch('serviceTypes')

  const fetchCoverages = useCallback(
    async (pagination: Pagination) => {
      let result: Result<CoverageQueryResponse> = {
        data: [],
        totalElements: 0,
      }

      if (name) {
        reset()
        setValue('name', name)
        setFilters(watch())
      }

      try {
        const response = await CoverageDriver.query({
          ...pagination,
          ...(name && { name }),
          ...(coverageType && { coverageType }),
          ...(state && { stateId: state.id }),
          ...(city && { cityId: city.id }),
          ...(district && { districtId: district.id }),
          ...(account && { accountId: account.id }),
          ...(tag && { accountTags: [tag.name] }),
          ...(serviceOrderType && { serviceOrderType }),
          ...(occurrenceCategory && { occurrenceCategory }),
          ...(serviceTypes?.length && {
            serviceTypeIds: serviceTypes.map((serviceType) => serviceType.id),
          }),
        })

        result = {
          data: response.data,
          totalElements: response.totalElements,
        }
      } finally {
        setResetPagination(false)
      }

      return result
    },
    [
      account,
      city,
      coverageType,
      district,
      name,
      occurrenceCategory,
      reset,
      serviceOrderType,
      setFilters,
      setValue,
      state,
      tag,
      watch,
      serviceTypes,
    ],
  )

  const handleRemoveAttendanceProfile = (
    attendanceProfile: CoverageQueryResponse,
  ) => {
    setSelectedAttendanceProfile(attendanceProfile)
    removeAttendanceProfileModalProps.show()
  }

  const removeAttendanceProfile = useCallback(async () => {
    if (!selectedAttendanceProfile) return
    try {
      await CoverageDriver.delete(selectedAttendanceProfile.id)
      addToast({
        message: 'Perfil de atendimento excluído com sucesso.',
      })
      tableRef.current?.handleFetchPage()
    } catch (error) {
      addToast({
        message: 'Erro ao excluir perfil de atendimento. Tente novamente.',
        type: 'alert',
      })
    } finally {
      setSelectedAttendanceProfile(undefined)
      removeAttendanceProfileModalProps.hide()
    }
  }, [addToast, selectedAttendanceProfile, removeAttendanceProfileModalProps])

  const handleCloseRemoveAttendanceProfileModal = () => {
    setSelectedAttendanceProfile(undefined)
    removeAttendanceProfileModalProps.hide()
  }

  useEffect(() => {
    setHasFilter(
      Object.values(watch())
        .slice(1, 7)
        .some((value) => value),
    )
  }, [watch, filterSheetProps.isVisible])

  useEffect(() => {
    register('coverageType')
    register('state')
    register('city')
    register('district')
    register('account')
    register('tag')
    register('serviceOrderType')
    register('occurrenceCategory')
    register('serviceTypes')

    return () => {
      unregister('coverageType')
      unregister('state')
      unregister('city')
      unregister('district')
      unregister('account')
      unregister('tag')
      unregister('serviceOrderType')
      unregister('occurrenceCategory')
      unregister('serviceTypes')
    }
  }, [register, unregister])

  return (
    <>
      <AttendanceProfileDeleteModal
        isVisible={removeAttendanceProfileModalProps.isVisible}
        onClose={handleCloseRemoveAttendanceProfileModal}
        onClick={removeAttendanceProfile}
        profileName={selectedAttendanceProfile?.name || ''}
        vinculatedUsers={selectedAttendanceProfile?.totalUsers}
      />
      <FormProvider {...form}>
        <Filters
          isVisible={filterSheetProps.isVisible}
          onApplyFilters={() => {
            if (name) {
              setValue('name', undefined)
            }
            setFilters(watch())
            setResetPagination(true)
            filterSheetProps.hide()
            tableRef.current?.handleFetchPage()
          }}
          onClose={filterSheetProps.hide}
        />
      </FormProvider>
      <ContainerScreen
        clickable={!filterSheetProps.isVisible}
        title="Perfis de atendimento"
        renderFilters={
          <form
            onSubmit={(event) => {
              event.preventDefault()
              setResetPagination(true)
              tableRef.current?.handleFetchPage()
            }}
          >
            <section className={styles.innerWrapper}>
              <Input
                id="name"
                label="Nome"
                placeholder="Digite o nome do perfil"
                {...form.register('name')}
                autoComplete="off"
                disabled={filterSheetProps.isVisible}
              />
              <Button
                buttonTitle="Pesquisar"
                type="primary"
                htmlType="submit"
              />
              <Button
                buttonTitle="Novo perfil"
                type="secondary"
                icon={PlusIcon}
                width="143px"
                onClick={() =>
                  navigate(paths.configuration.attendanceProfile.create)
                }
              />
              <Button
                buttonTitle={hasFilter ? 'Editar filtros' : 'Adicionar filtros'}
                type="tertiary"
                icon={FiltersIcon}
                onClick={filterSheetProps.show}
              />
            </section>
          </form>
        }
      >
        <Table
          innerRef={tableRef}
          columns={columns}
          paginated
          actions={[
            {
              label: 'Visualizar',
              Icon: InspectIcon,
              handler: (attendanceProfile) =>
                navigate(
                  buildPath({
                    path: paths.configuration.attendanceProfile.update,
                    params: { attendanceProfileId: attendanceProfile.id },
                  }),
                ),
            },
            {
              label: 'Excluir',
              Icon: DeleteIcon,
              handler: (attendanceProfile) =>
                handleRemoveAttendanceProfile(attendanceProfile),
            },
          ]}
          resetPagination={resetPagination}
          fetcher={fetchCoverages}
        />
      </ContainerScreen>
    </>
  )
}

export default AttendanceProfile
